import React from 'react'
import Header from '../components/header/Header'
import CTAComponent from '../components/cta/Cta'
import PartnershipComponent from '../components/partnership/Partnership'
import Objectives from '../components/objectives/Objectives'
import About from '../components/about/About'
import Articles from '../components/articles/Articles'
import HomeCss from './css/HomeCss.css'

function Home() {
  return (
    <div className="app-container">
      <Header />
      <div id='cta' className="cta-section">
        <CTAComponent />
      </div>
      <div id='partnerships' className="partnership-section">
        <PartnershipComponent />
      </div>
      <div id='about' className="about-section">
        <About />
      </div>
      <div id='objectives' className="objectives-section">
        <Objectives />
      </div>
      <div id="articles" className="articles-section">
        <Articles />
      </div>
    </div>
  )
}

export default Home