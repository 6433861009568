// About.js
import React from 'react';
import FadeIn from 'react-fade-in';
import './About.css'
import './fonts.css'
import { Link } from 'react-router-dom';

// ...


const About = () => {
  const containerStyles = {
    position: 'relative',
    width:'100%'
   
  };

  const imageStyles = {
   
    backgroundImage: 'url("/images/headerpic5.jpg")', // Add your image path
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '10px', // Add rounded corners
    zIndex: 1,
  };

  return (
    <div className='about-container'>
    <div className='container-styles' style={containerStyles}>
      <div className='image-styles'style={imageStyles}></div>
      <FadeIn>
      <div className='card-styles'>
      <h2 style={{ fontFamily: 'Protest Guerrilla, sans-serif', fontSize: '2.8em' }}>About Us</h2>
      <p className='about-paragraph' style={{ fontFamily: 'serif', fontWeight: 'bold' }}>
  Welcome to the Queen Fatima Girl Child Foundation, where every girl child's smile is our inspiration. Our journey began with a simple yet powerful belief – that every girl child deserves a happy and healthy childhood. We are dedicated to making a positive impact on the lives of children around the world by providing love, care, and educational opportunities.
</p>



<button style={buttonStyles}>
  <Link to="/about" style={{ color: 'white', textDecoration: 'none' }}>
    Learn More
  </Link>
</button>      </div>
      </FadeIn>
    </div>
    </div>
  );
};



const buttonStyles = {
  backgroundColor: '#6C22A6',
  color: '#fff',
  padding: '10px 20px',
  borderRadius: '5px',
  marginTop: '0px',
  cursor: 'pointer',
  border: 'none',
  fontSize: '16px',
  marginBottom:'10%'
};

export default About;
