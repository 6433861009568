import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaCalendarAlt, FaUser } from 'react-icons/fa'; // Import appropriate icons
import './Articles.css';
import FadeIn from 'react-fade-in';


const Articles = () => {
  const articles = [
    {
      id: 1,
      image: '/images/article1.jpg',
      headline: 'Empowering Children Through Education',
      author: 'Child Empowerment Team',
      date: 'February 1, 2024',
      type: 'Press Release',
    },
    {
      id: 2,
      image: '/images/article2.jpg',
      headline: 'Creating Smiles: Our Journey in Child Healthcare',
      author: 'Queen Fatima Emetumah',
      date: 'February 2, 2024',
      type: 'Blog',
    },
    {
      id: 3,
      image: '/images/article3.jpg',
      headline: 'Fostering Joy: A Look into Our Child-Focused Programs',
      author: 'Program Impact Team',
      date: 'February 3, 2024',
      type: 'Blog',
    },
    {
      id: 4,
      image: '/images/article1.jpg',
      headline: 'Nurturing Dreams: Stories of Children Overcoming Challenges',
      author: 'Inspiration Stories Team',
      date: 'February 4, 2024',
      type: 'Blog',
    },
  ];

  return (
    <>
      <h1 className='article-header' style={{ fontFamily: 'Protest Guerrilla, sans-serif',  marginLeft:'4%', marginBottom:'3%' }}>Latest Articles</h1>
      <Row xs={1} md={2} lg={2} className="g-4" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
        {articles.map((article) => (
          <FadeIn delay={200} transitionDuration={800}>
          <Col key={article.id}>
            <Link to={`/article/${article.id}`} className="article-link">
              <div className="article-card">
                <img src={article.image} alt={`Article ${article.id}`} className="article-image" />
                <div style={{backgroundColor:'#6C22A6'}}className="article-tag">{article.type}</div>
                <div style={{marginTop: '4%'}} className="article-content">
                  <h3>{article.headline}</h3>
                  <p>
                    <FaUser style={{ marginRight: '5px', color: 'grey' }} />
                    {article.author}
                  </p>
                  <p>
                    <FaCalendarAlt style={{ marginRight: '5px' }} />
                    {article.date}
                  </p>
                </div>
              </div>
            </Link>
          </Col>
          </FadeIn>
        ))}
      </Row>
    </>
  );
};

export default Articles;
