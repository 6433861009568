// PartnershipComponent.js
import React, { useState} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Partnership.css'
import './fonts.css'; // Import the CSS file
import { useEffect } from 'react';
import $ from 'jquery'; 
import FadeIn from 'react-fade-in';
import Fade from 'react-reveal/Fade';


const PartnershipComponent = () => {
  const placeholderLogos = Array.from({ length: 8 }, (_, index) => index + 1);
  const [words] = useState([
    'Our partnership is made up of more than 1600 organisations in over 100 countries.',
  'Together, we work towards ending child marriage and enabling girls to fulfill their potential.',
  'Your support helps provide education, healthcare, and protection for vulnerable children worldwide.',
  'Join us in creating a world where every child is free to dream and thrive.',
]);
  let part, i = 0, offset = 0, len = words.length, forwards = true, skipCount = 0, skipDelay = 15, speed = 70;

  const wordFlick = () => {
    setInterval(() => {
      if (forwards) {
        if (offset >= words[i].length) {
          ++skipCount;
          if (skipCount === skipDelay) {
            forwards = false;
            skipCount = 0;
          }
        }
      } else {
        if (offset === 0) {
          forwards = true;
          i++;
          offset = 0;
          if (i >= len) {
            i = 0;
          }
        }
      }
      part = words[i].substr(0, offset);
      if (skipCount === 0) {
        if (forwards) {
          offset++;
        } else {
          offset--;
        }
      }
      $('.word').text(part);
    }, speed);
  };

  useEffect(() => {
    wordFlick();
  }, []); // Run the effect only once on component mount

  return (
    <Container className="mt-4">
      <h2 className="partnership-header" style={{ fontFamily: 'Protest Guerrilla, sans-serif',}}>Our Partnerships</h2>
      <div className="word-container" style={{ height: '70px' }}>
      <p className="word" style={{ fontFamily: 'serif', fontSize: '1.4em' }}>Our partnership is made up of more than 1600 organisations in over 100 countries.</p>

      </div>

<Fade >
<div className='partners-row'>
<Row className="mt-4">
  <Col xs={6} md={2} className="mb-4">
    <div className="partner-logo border">
      <img
        src={`/images/charity2.png`}
        alt="Partner Logo 1"
        className="img-fluid"
      />
    </div>
  </Col>
  <Col xs={6} md={2} className="mb-4">
    <div className="partner-logo border">
      <img
        src={`/images/charity4.png`}
        alt="Partner Logo 1"
        className="img-fluid"
      />
    </div>
  </Col>

  <Col xs={6} md={2} className="mb-4">
    <div className="partner-logo border">
      <img
        src={`/images/charity.png`}
        alt="Partner Logo 1"
        className="img-fluid"
      />
    </div>
  </Col>
  <Col xs={6} md={2} className="mb-4">
    <div className="partner-logo border">
      <img
        src={`/images/charity8.png`}
        alt="Partner Logo 1"
        className="img-fluid"
      />
    </div>
  </Col>
  <Col xs={6} md={2} className="mb-4">
    <div className="partner-logo border">
      <img
        src={`/images/charity6.png`}
        alt="Partner Logo 1"
        className="img-fluid"
      />
    </div>
  </Col>
    <Col xs={6} md={2} className="mb-4">
    <div className="partner-logo border">
      <img
        src={`https://img.freepik.com/premium-vector/vector-logo-illustration-charity-gradient-colorful-style_474888-1749.jpg`}
        alt="Partner Logo 1"
        className="img-fluid"
      />
    </div>
  </Col>  <Col xs={6} md={2} className="mb-4">
    <div className="partner-logo border">
      <img
        src={`https://cdn4.vectorstock.com/i/1000x1000/25/88/charity-logo-design-template-vector-21462588.jpg`}
        alt="Partner Logo 1"
        className="img-fluid"
      />
    </div>
  </Col>  <Col xs={6} md={2} className="mb-4">
    <div className="partner-logo border">
      <img
        src={`https://w7.pngwing.com/pngs/987/612/png-transparent-muhammadiyah-cdr-quran-islam-ngo-leaf-text-logo-thumbnail.png`}
        alt="Partner Logo 1"
        className="img-fluid"
      />
    </div>
  </Col>  <Col xs={6} md={2} className="mb-4">
    <div className="partner-logo border">
      <img
        src={`https://d1csarkz8obe9u.cloudfront.net/posterpreviews/charity-logo%2C-charity-icon%2Cred-heart-charity-design-template-009f146fb7a178a3cf152b189bd249d9_screen.jpg?ts=1661666080`}
        alt="Partner Logo 1"
        className="img-fluid"
      />
    </div>
  </Col>  <Col xs={6} md={2} className="mb-4">
    <div className="partner-logo border">
      <img
        src={`/images/charity9.png`}
        alt="Partner Logo 1"
        className="img-fluid"
      />
    </div>
  </Col>  <Col xs={6} md={2} className="mb-4">
    <div className="partner-logo border">
      <img
        src={`https://marketplace.canva.com/EAFavu6O5ks/1/0/1600w/canva-pink-colorful-illustrated-modern-charity-foundation-logo-u-T5FOkZXlM.jpg`}
        alt="Partner Logo 1"
        className="img-fluid"
      />
    </div>
  </Col>
  <Col xs={6} md={2} className="mb-4">
    <div className="partner-logo border">
      <img
        src={`/images/placeholder-logo${2}.png`}
        alt="Partner Logo 2"
        className="img-fluid"
      />
    </div>
  </Col>
  {/* Repeat the structure for other logos as needed */}
</Row>
</div>
    
</Fade>
    </Container>
  );
};

export default PartnershipComponent;
