import React from 'react';
import './css/About.css';
import './fonts.css'; // Import the CSS file

const About = () => {
  const containerStyles = {
    position: 'relative',
    height: '400px', // Adjust the height as needed
    backgroundImage: `url(${'/images/headerpic3.jpg'})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  };

  const overlayStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    width: '70%', // Adjust the width as needed
    color: 'white',
    zIndex: 1,
  };

  return (
    <>
    <div style={containerStyles}>
      <div style={overlayStyles}>
        <h1 style={{ fontSize: '2.5rem', fontWeight: 'bold' }}>
          About Us
        </h1>
        <p>
          Find out more about Queen Fatima Girl Child Foundation: our vision, mission,
          organisational structure and ways of working.
        </p>
      </div>
    </div>
    <section>
      <h2 style={{  fontFamily: 'Protest Guerrilla, sans-serif',
    fontSize: '2.8em',
    marginBottom: '1rem',
    borderBottom: '8px solid #6C22A6', // Thick purple underline
    paddingBottom: '0.3rem' }}>Our Focus</h2>
      <p>Welcome to the Queen Fatima Girl Child Foundation, a beacon of hope for children in need. Our organization is driven by the belief that every child deserves a bright and promising future. With a focus on educational empowerment, health and nutrition, and community development, we strive to make a positive impact on the lives of children across the globe. Through our dedicated teams and impactful programs, we work tirelessly to provide quality education, ensure access to proper healthcare and nutrition, and foster community development to create a positive environment for children to thrive. Join us in our mission to make a difference in the lives of these young minds, as we believe that together, we can create a better world for the future generation.</p>


      <h2 style={{  fontFamily: 'Protest Guerrilla, sans-serif',
    fontSize: '2.8em',
    marginBottom: '1rem',
    borderBottom: '8px solid #6C22A6', // Thick purple underline
    paddingBottom: '0.3rem' }}>Our Vision</h2>
      <p>Our vision is a world where every child has access to quality education, healthcare, and a nurturing environment that enables them to reach their full potential. At the NGO for Children, we are committed to creating a brighter future for the next generation by focusing on educational empowerment, ensuring proper healthcare and nutrition, and fostering community development. Through our unwavering dedication and impactful initiatives, we aspire to break down barriers and provide opportunities that will empower children to thrive and contribute positively to society. Join us in turning this vision into reality as we work towards building a world where every child's dreams can come true.</p>


      <h2 style={{  fontFamily: 'Protest Guerrilla, sans-serif',
    fontSize: '2.8em',
    marginBottom: '1rem',
    borderBottom: '8px solid #6C22A6', // Thick purple underline
    paddingBottom: '0.3rem' }}>Our Mission</h2>
      <p>At our NGO, we are guided by a mission to transform lives and empower communities. Our core commitments include:</p>


      <ul>
    <li>
        <strong>Providing Education:</strong> We believe in the power of education to break the cycle of poverty. Through our educational programs, we strive to provide quality learning opportunities, resources, and support to children, enabling them to build a strong foundation for a better future.
    </li>
    <li>
        <strong>Ensuring Access to Healthcare and Nutrition:</strong> Every child deserves access to proper healthcare and nutrition for their overall well-being. Our initiatives focus on creating avenues for children to receive necessary medical care, nutritious meals, and essential health services, promoting their growth and development.
    </li>
    <li>
        <strong>Advocating for Children's Rights and Protection:</strong> We are staunch advocates for children's rights. Our commitment extends to raising awareness about child protection issues, working towards policy changes, and collaborating with communities and authorities to create safe environments where children can thrive, free from harm and exploitation.
    </li>
    <li>
        <strong>Empowering Communities:</strong> We believe in the strength of communities to support child development. Our community empowerment programs focus on fostering collaboration, providing resources, and facilitating sustainable initiatives that uplift communities, ensuring a conducive environment for the growth and well-being of children.
    </li>
</ul>

    </section>
    </>
  );
};

export default About;
