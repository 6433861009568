// App.js
import ReactDOM from "react-dom/client";
import React from 'react';
import { BrowserRouter as Router, Routes ,Route, BrowserRouter, Navigate } from 'react-router-dom'; // If you are using React Router
import Navbar from './components/navbar/Navbar';
import Home from './pages/Home';
import Footer from "./components/footer/Footer";
import ContactUs from "./pages/Contact";
import About from "./pages/About";

import ArticleDetails from "./pages/ArticleDetails";
const NotFound = () => <h2>Page not found</h2>;

// Add similar components for other routes

const App = () => {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route index element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/article/:id" element={<ArticleDetails />} />
                {/* Add other routes for different pages */}
        <Route path="*" element={<Navigate to="/404" />} />
        <Route path="/404" element={<NotFound />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
