// ArticleDetails.js
import React from 'react';
import { useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap'; // Assuming you're using React Bootstrap for styling

const ArticleDetails = () => {
  const { id } = useParams();

  // Array of articles with bodies
  const articles = [
    {
      id: 1,
      image: '/images/headerpic1.jpg',
      headline: 'Empowering Children Through Education',
      author: 'Child Empowerment Team',
      date: 'February 1, 2024',
      type: 'Blog',
      body: `
        <p>Welcome to the NGO for Children, a beacon of hope for children in need. Our organization is driven by the belief that every child deserves a bright and promising future. With a focus on educational empowerment, health and nutrition, and community development, we strive to make a positive impact on the lives of children across the globe. Through our dedicated teams and impactful programs, we work tirelessly to provide quality education, ensure access to proper healthcare and nutrition, and foster community development to create a positive environment for children to thrive. Join us in our mission to make a difference in the lives of these young minds, as we believe that together, we can create a better world for the future generation.</p>
        
        <p>Our vision is a world where every child has access to quality education, healthcare, and a nurturing environment that enables them to reach their full potential. At the NGO for Children, we are committed to creating a brighter future for the next generation by focusing on educational empowerment, ensuring proper healthcare and nutrition, and fostering community development. Through our unwavering dedication and impactful initiatives, we aspire to break down barriers and provide opportunities that will empower children to thrive and contribute positively to society. Join us in turning this vision into reality as we work towards building a world where every child's dreams can come true.</p>
        
        <p>At our NGO, we are guided by a mission to transform lives and empower communities. Our core commitments include:</p>
    
        <ul>
          <li><strong>Providing Education:</strong> We believe in the power of education to break the cycle of poverty...</li>
          <li><strong>Ensuring Access to Healthcare and Nutrition:</strong> Every child deserves access to proper healthcare and nutrition...</li>
          <li><strong>Advocating for Children's Rights and Protection:</strong> We are advocates for children's rights...</li>
          <li><strong>Empowering Communities:</strong> We believe in the strength of communities to support child development...</li>
        </ul>
      `,
    },    
    {
      id: 2,
      image: '/images/article2.jpg',
      headline: 'Creating Smiles: Our Journey in Child Healthcare',
      author: 'Queen Fatima Emetumah',
      date: 'February 2, 2024',
      type: 'Blog',
      body: `
        <p>Join us on a heartfelt journey as we explore the profound impact of child healthcare on creating smiles and transforming lives. Queen Fatima Emetumah, a passionate advocate for children's well-being, shares our organization's inspiring initiatives in the realm of child healthcare.</p>
        
        <p>At the heart of our mission is the belief that every child deserves access to quality healthcare, ensuring their physical well-being and fostering a foundation for a healthy future. Queen Fatima, with her unwavering dedication, narrates the stories of resilience, hope, and joy that have emerged from our healthcare programs.</p>
        
        <p>As we delve into the challenges and triumphs of our journey, we invite you to be a part of the narrative that celebrates the transformative power of healthcare in creating smiles. Join us in this blog as we showcase the collective efforts that contribute to the well-being and happiness of the children we serve.</p>
        
        <p>Together, let's continue on the path of compassion and care, ensuring that every child experiences the joy of a healthy and fulfilling childhood.</p>
      `,
    },
    {
      id: 3,
      image: '/images/article3.jpg',
      headline: 'Fostering Joy: A Look into Our Child-Focused Programs',
      author: 'Program Impact Team',
      date: 'February 3, 2024',
      type: 'Blog',
      body: `
        <p>Embark on a journey with our Program Impact Team as we explore the heartwarming world of our child-focused programs. In this insightful blog, we invite you to gain a deeper understanding of the initiatives that bring joy, growth, and positive impact to the lives of children.</p>
        
        <p>The Program Impact Team, comprised of dedicated individuals driven by a passion for child development, shares stories and anecdotes that reflect the essence of our programs. From educational empowerment to community engagement, each program is designed with a singular focus – fostering joy in the lives of the children we serve.</p>
        
        <p>As we shine a spotlight on the diverse aspects of our child-focused programs, you'll witness the transformative power of collective efforts. Join us in celebrating the milestones, achievements, and heartwarming moments that define the impact of our programs on the children and communities we support.</p>
        
        <p>Together, let's explore the realm of possibilities that arise when joy becomes a guiding principle in shaping the future of every child. Join us on this enriching journey and be inspired by the joy that emanates from our commitment to making a difference.</p>
      `,
    },
    {
      id: 4,
      image: '/images/article1.jpg',
      headline: 'Nurturing Dreams: Stories of Children Overcoming Challenges',
      author: 'Inspiration Stories Team',
      date: 'February 4, 2024',
      type: 'Blog',
      body: `
        <p>Join the Inspiration Stories Team in a journey that unfolds tales of resilience, hope, and triumph. In this blog, we delve into the inspiring narratives of children who have overcome challenges and emerged as beacons of strength.</p>
        
        <p>Our team is dedicated to capturing and sharing the untold stories of courage that define the journey of these remarkable children. Through firsthand accounts, interviews, and narratives, we bring you closer to the dreams and aspirations that drive them forward.</p>
        
        <p>Nurturing dreams goes beyond providing basic necessities; it involves empowering children to envision a future filled with possibilities. In this collection of stories, witness the transformative impact of support, encouragement, and belief in the potential of every child.</p>
        
        <p>These narratives serve as a testament to the collective efforts of our organization and the communities that stand by these children. Each story is a celebration of resilience and the unwavering spirit that propels these young minds towards a brighter future.</p>
        
        <p>Prepare to be inspired as we share stories that reflect the power of nurturing dreams and the positive change it brings to the lives of children. Together, let's champion the dreams of every child and create a world where challenges are met with unwavering determination and support.</p>
      `,
    }
    // ... other articles
  ];

  // Find the article with the matching ID
  const selectedArticle = articles.find(article => article.id.toString() === id);

  // Display details based on the selected article
  return (
    <Container  style={{marginBottom:'8%'}}className="mt-4">
    <h2>{selectedArticle?.headline}</h2>
      <p>By {selectedArticle?.author} on {selectedArticle?.date}</p>
      <div className="text-center">
        <img
          src={selectedArticle?.image}
          alt={`Article ${selectedArticle?.id}`}
          className="img-fluid"
          style={{ width: '100%', maxHeight: '500px', marginBottom: '20px' }}
        />
      </div>
      
      <div>
        <h3>Article Details</h3>
        {selectedArticle ? (
          <div dangerouslySetInnerHTML={{ __html: selectedArticle.body }} />
        ) : (
          <p>Article not found</p>
        )}
      </div>
    </Container>
  );
};

export default ArticleDetails;
