// Footer.js
import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using React Router
import './Footer.css'; // Import the CSS file
import { FaTwitter, FaLinkedin, FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa';


const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-grid">
        <div className="footer-links">
          <h4>Explore</h4>
          <Link to="https://www.unicef.org/protection/child-marriage">About child marriage</Link>
          <Link to="/about">About us</Link>
          <a href="#partnerships">Our Partnerships</a>
          {/* <Link to="/learning-resources">Learning & Resources</Link> */}
          {/* <Link to="/take-action">Take action</Link> */}
          {/* <Link to="/articles">Articles</Link>
          <Link to="/press-centre">Press centre</Link> */}
          <Link to="/contact-us">Contact</Link>

        </div>

        <div className="social-media-links">
      <h4>Follow Us</h4>
      <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
        <FaTwitter style={{ marginRight: '5px' }} />
        Twitter
      </a>
      <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
        <FaLinkedin style={{ marginRight: '5px' }} />
        LinkedIn
      </a>
      <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
        <FaFacebook style={{ marginRight: '5px' }} />
        Facebook
      </a>
      <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
        <FaInstagram style={{ marginRight: '5px' }} />
        Instagram
      </a>
      <a href="https://youtube.com" target="_blank" rel="noopener noreferrer">
        <FaYoutube style={{ marginRight: '5px' }} />
        Youtube
      </a>
    </div>

        <div className="contact-info">
          <h4>Contact Us</h4>
          <p>5 Emetumah Street Doverly Hotel Road, Irete
Imo, Owerri West , 460281
Nigeria</p>
          <p>Call us: +234-80-5658-3492</p>
          <p>Write us: info@queenfatimagirlchildfoundation.org</p>
        </div>

        
      </div>
      
      <div className="copyright-info">
        <p>&copy; Copyright 2002 - 2024 All Rights Reserved. Queen Fatima Girl Child Foundation is a company limited by guarantee (Reg. No. 8570751) and a registered NGO.</p>
      </div>
      {/* <div className="legal-links">
        <p> <Link to="/terms-and-conditions">Terms & Conditions</Link>
          <Link to="/privacy-policy">Privacy Policy</Link></p>  
        </div> */}
    </footer>
  );
};

export default Footer;
