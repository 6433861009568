import React, { useState } from 'react';
import './Navbar.css'; // You can create a CSS file for styling
import './fonts.css'
const Link = ({ to, children }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const linkStyle = {
    textDecoration: 'none',
    color: isHovered ? '#FFD0EC' : 'white',
    
  };

  return (
    <a href={to} className='link-style' style={linkStyle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {children}
    </a>
  );
};

const Navbar = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  return (
    <nav className="navbar">
      <div className="navbar-container">
        {/* <a style={{ fontFamily: 'Protest Guerrilla, sans-serif', fontSize: '3.3em', }} href="/" className="navbar-brand">
          NGO Brand
        </a> */}
        <img
        
  src="/images/qfgcf-logo.png"  // Make sure to use the correct file extension (e.g., .jpg, .png)
  alt="QFGCF Logo"
  className="navbar-brand logo"  // You can add a class for styling if needed
/>

      </div>
      <div className="menu-btn" onClick={toggleDropdown}>
      &#9776;
          MENU
        </div>
      <div style={{ fontFamily: 'Protest Guerrilla, sans-serif', marginRight:'10%'  }} className="navbar-links links-container">
        <Link  to="/">HOME</Link>
        <Link to="/about">ABOUT</Link>
        <Link to="/article/1">BLOG</Link>
        <Link to="/contact-us">CONTACT US</Link>
      </div>
      {isDropdownOpen && (
          <div className="dropdown">
            <Link to="/">HOME</Link>
            <Link to="/about">ABOUT</Link>
            <Link to="/article/1">BLOG</Link>
            <Link to="/contact-us">CONTACT US</Link>
          </div>
        )}
    </nav>
  );
};

export default Navbar;
