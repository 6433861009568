// CTAComponent.js
import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  FacebookShareCount,
  GabIcon,
  GabShareButton,
  HatenaIcon,
  HatenaShareButton,
  HatenaShareCount,
  InstapaperIcon,
  InstapaperShareButton,
  LineIcon,
  LineShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  LivejournalIcon,
  LivejournalShareButton,
  MailruIcon,
  MailruShareButton,
  OKIcon,
  OKShareButton,
  OKShareCount,
  PinterestIcon,
  PinterestShareButton,
  PinterestShareCount,
  PocketIcon,
  PocketShareButton,
  RedditIcon,
  RedditShareButton,
  RedditShareCount,
  TelegramIcon,
  TelegramShareButton,
  TumblrIcon,
  TumblrShareButton,
  TumblrShareCount,
  TwitterShareButton,
  ViberIcon,
  ViberShareButton,
  VKIcon,
  VKShareButton,
  VKShareCount,
  WeiboIcon,
  WeiboShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  WorkplaceIcon,
  WorkplaceShareButton,
  XIcon,
} from "react-share";
import  './Cta.css'
import './fonts.css'; // Import the CSS file
import Fade from 'react-reveal/Fade';


const shareUrl = "http://github.com";
  const title = "GitHub";

const CTAComponent = () => {
  return (
    <div>
   <Fade >
    <Container>
      <h2 className="section-title" style={{ fontFamily: 'Protest Guerrilla, sans-serif',}}>How You Can Help a Child</h2>

      <Row className="mt-4">
        {/* <Col>
          <Card className="custom-card">
            <Card.Body>
              <Card.Title ><h3 style={{ fontFamily: 'Protest Guerrilla, sans-serif',}}>Donate</h3></Card.Title>
              <Card.Text style={{ fontFamily: 'serif',fontSize:'1.1rem' }}>
                Your contributions can make a significant impact on a child's life. Consider donating to support education, healthcare, and overall well-being.
              </Card.Text>
            </Card.Body>
            <Card.Footer>
            <Button style={{ width: '100%', marginTop: '10px', marginBottom: '10px', backgroundColor: '#6C22A6' }}>Donate</Button>
            </Card.Footer>
          </Card>
        </Col> */}
        <Col>
          <Card className="custom-card">
            <Card.Body>
              <Card.Title ><h3 style={{ fontFamily: 'Protest Guerrilla, sans-serif',}}>Foster</h3></Card.Title>
              <Card.Text style={{ fontFamily: 'serif',fontSize:'1.1rem' }}>
              Consider fostering a child if you are able to provide a stable and loving home.
              </Card.Text>
            </Card.Body>
            <Card.Footer>
            <Link to="/contact-us">
      <Button style={{ width: '100%', marginTop: '10px', marginBottom: '10px', backgroundColor: '#6C22A6' }}>
        Learn How
      </Button>
    </Link>            </Card.Footer>
          </Card>
        </Col>
        <Col>
          <Card className="custom-card">
            <Card.Body>
              <Card.Title ><h3 style={{ fontFamily: 'Protest Guerrilla, sans-serif',}}>Spread Awareness</h3></Card.Title>
              <Card.Text style={{ fontFamily: 'serif',fontSize:'1.1rem' }}>
          Advocate for children's rights and well-being. Share information, participate in campaigns, and help raise awareness about issues affecting children.
        </Card.Text>
            </Card.Body>
            <Card.Footer>
              <div className="share-buttons-container">
  <FacebookShareButton url={shareUrl} className="Demo__some-network__share-button">
    <FacebookIcon size={32} round />
  </FacebookShareButton>

  <FacebookShareCount url={shareUrl} className="Demo__some-network__share-count">
    {(count) => count}
  </FacebookShareCount>

  <TwitterShareButton url={shareUrl} title={title} className="Demo__some-network__share-button">
    <XIcon size={32} round />
  </TwitterShareButton>

  <TelegramShareButton url={shareUrl} title={title} className="Demo__some-network__share-button">
    <TelegramIcon size={32} round />
  </TelegramShareButton>

  <WhatsappShareButton url={shareUrl} title={title} separator=":: " className="Demo__some-network__share-button">
    <WhatsappIcon size={32} round />
  </WhatsappShareButton>

  <LinkedinShareButton url={shareUrl} className="Demo__some-network__share-button">
    <LinkedinIcon size={32} round />
  </LinkedinShareButton>
 

        <RedditShareButton
          url={shareUrl}
          title={title}
          windowWidth={660}
          windowHeight={460}
          className="Demo__some-network__share-button"
        >
          <RedditIcon size={32} round />
        </RedditShareButton>


       

      



        <MailruShareButton
          url={shareUrl}
          title={title}
          className="Demo__some-network__share-button"
        >
          <MailruIcon size={32} round />
        </MailruShareButton>

        <EmailShareButton
          url={shareUrl}
          subject={title}
          body="body"
          className="Demo__some-network__share-button"
        >
          <EmailIcon size={32} round />
        </EmailShareButton>
</div>

                      </Card.Footer>
          </Card>
        </Col>
        <Col>
          <Card className="custom-card">
            <Card.Body>
            <Card.Title ><h3 style={{ fontFamily: 'Protest Guerrilla, sans-serif',}}>Volunteer</h3></Card.Title>
              <Card.Text style={{ fontFamily: 'serif',fontSize:'1.1rem' }}>
                Spend your time ring for our organization. Your time and skills can contribute to creating positive experiences for children.
              </Card.Text>
            </Card.Body>
            <Card.Footer>
            <Link to="/contact-us">
      <Button style={{ width: '100%', marginTop: '10px', marginBottom: '10px', backgroundColor: '#6C22A6' }}>
        Volunteer
      </Button>
    </Link>            </Card.Footer>
          </Card>
        </Col>

</Row>
    </Container>
    </Fade> 
     </div>
  );
};

export default CTAComponent;
