import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import './fonts.css'; // Import the CSS file
import FadeIn from 'react-fade-in';
import Fade from 'react-reveal/Fade';
import './Header.css'

const taglineStyle = {
 
  fontFamily: 'Protest Revolution, sans-serif', // Use the correct font family name

};


const Header = () => {
  return (
    <Carousel className='carousel' > {/* Adjust the maxHeight as needed */}
      <Carousel.Item>
      <div className="carousel-overlay"></div>
        <img
          className="d-block w-100 darken-image"
          src="/images/headerpic1.jpg"
          alt="First slide"
                    style={{ objectFit: 'cover' }} 

        />
        <Carousel.Caption style={{marginBottom:'10%'}} className="d-flex flex-column align-items-center justify-content-center">
<h3 className="tagline" style={taglineStyle}>QUEEN FATIMA GIRL CHILD FOUNDATION</h3>
      <p className="tagline-text">Empowering The Girl Child, Building Bright Futures.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 darken-image"
          src="/images/headerpic6.jpg"
          alt="Fourth slide"
                    style={{ objectFit: 'cover' }} 

        />
        <Carousel.Caption style={{marginBottom:'10%'}} className="d-flex flex-column align-items-center justify-content-center">
<h3 className="tagline" style={taglineStyle}>QUEEN FATIMA GIRL CHILD FOUNDATION</h3>
      <p className="tagline-text">Empowering The Girl Child, Building Bright Futures.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 darken-image"
          src="/images/headerpic9.jpg"
          alt="Second slide"
                    style={{ objectFit: 'cover' }}  

        />
        <Carousel.Caption style={{marginBottom:'10%'}} className="d-flex flex-column align-items-center justify-content-center">
<h3 className="tagline" style={taglineStyle}>QUEEN FATIMA GIRL CHILD FOUNDATION</h3>
      <p className="tagline-text">Empowering The Girl Child, Building Bright Futures.</p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100 darken-image"
          src="/images/headerpic3.jpg"
          alt="Third slide"
                    style={{ objectFit: 'cover' }}  

        />
        <Carousel.Caption style={{marginBottom:'10%'}} className="d-flex flex-column align-items-center justify-content-center">
<h3 className="tagline" style={taglineStyle}>QUEEN FATIMA GIRL CHILD FOUNDATION</h3>
      <p className="tagline-text">Empowering The Girl Child, Building Bright Futures.</p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100 darken-image"
          src="/images/headerpic2.jpg"
          alt="Fourth slide"
                    style={{ objectFit: 'cover' }} 

        />
        <Carousel.Caption style={{marginBottom:'10%'}} className="d-flex flex-column align-items-center justify-content-center">
<h3 className="tagline" style={taglineStyle}>QUEEN FATIMA GIRL CHILD FOUNDATION</h3>
      <p className="tagline-text">Empowering The Girl Child, Building Bright Futures.</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default Header;
