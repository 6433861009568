// Objectives.js
import React from 'react';
import './Objectives.css';
import FadeIn from 'react-fade-in';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';

import './fonts.css'

const objectivesData = [
  {
    id: 1,
    image: '/images/education.png',
    header: 'Educational Empowerment',
    description: 'Providing quality education to empower children for a brighter future.',
    learnMoreLink: '/article/3',
  },
  {
    id: 2,
    image: '/images/health.png',
    header: 'Health and Nutrition',
    description: 'Ensuring access to proper healthcare and nutrition for the well-being of children.',
    learnMoreLink: '/article/1',
  },
  {
    id: 3,
    image: '/images/community.png',
    header: 'Community Development',
    description: 'Fostering community development to create a positive environment for children.',
    learnMoreLink: '/article/2',
  },
  {
    id: 4,
    image: '/images/play.png',
    header: 'Empowering Women and Girls',
    description: 'Supporting initiatives that empower women and girls for a more inclusive society.',
    learnMoreLink: '/article/4',
  },
  {
    id: 5,
    image: '/images/equity.png',
    header: 'Environmental Sustainability',
    description: 'Promoting environmental sustainability for a healthier planet and future generations.',
    learnMoreLink: '/article/2',
  },
  {
    id: 6,
    image: '/images/se.png',
    header: 'Social Equality',
    description: 'Advocating for social equality to ensure every child has an equal opportunity to thrive.',
    learnMoreLink: '/article/1',
  },
  {
    id: 7,
    image: '/images/tech.png',
    header: 'Technology for Learning',
    description: 'Harnessing the power of technology to enhance learning experiences for children.',
    learnMoreLink: '/article/4',
  },
  {
    id: 8,
    image: '/images/environment.png',
    header: 'Sports and Physical Activity',
    description: 'Encouraging an active and healthy lifestyle through sports and physical activities.',
    learnMoreLink: '/article/3',
  },
];

const Objectives = () => {
  return (
    <Fade >
        <h1 className='obj-header' style={{ fontFamily: 'Protest Guerrilla, sans-serif', marginLeft:'6%', marginBottom:'5%' }}>Our Objectives</h1>
    <div className="objectives-container">
      {objectivesData.map((objective) => (
        <div key={objective.id} className="objective-card">
          <img src={objective.image} alt={`Objective ${objective.id}`} className="img-fluid" />
          <h3 style={{ fontFamily: 'Protest Guerrilla, sans-serif', fontSize: '1.3em' }}>{objective.header}</h3>
          <p>{objective.description}</p>
          <a href={objective.learnMoreLink} className="learn-more-link" target="_blank" rel="noopener noreferrer">
            Learn More
          </a>
        </div>
      ))}
    </div>
    </Fade>
  );
};

export default Objectives;
