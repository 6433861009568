// ContactUs.js
import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { FaMapMarkerAlt, FaPhone } from 'react-icons/fa';

import './fonts.css'

const ContactUs = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Implement your form submission logic here
    console.log('Form submitted!');
  };

  return (
    <div style={{marginBottom: '3%'}}>
 <div style={{ backgroundColor: 'black', height: '200px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
 <h2 className="text-center mb-4" style={{ fontSize: '2rem', fontWeight: 'bold', color: 'white', fontFamily: 'Protest Guerrilla, sans-serif', letterSpacing: '4px' }}>
    CONTACT US
  </h2>
</div>

    <Container className="mt-4">
  

      <Row>
        {/* Contact Information */}
        <Col style={{marginTop:'4%'}} md={6}>
        <h3 >Contact Information</h3>
  <p>
    <strong><FaMapMarkerAlt /> Address:</strong> 5 Emetumah Street Doverly Hotel Road, Irete
Imo, Owerri West , 460281
Nigeria
  </p>
  <p>
    <strong><FaPhone /> Phone:</strong> +234-80-5658-3492
  </p>
        </Col>

        {/* Contact Form */}
        <Col style={{marginTop:'4%', paddingRight:'5%'}} md={6}>
          <h3 >Send us a Message</h3>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder="Enter your name" required />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter your email" required />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formMessage">
              <Form.Label>Message</Form.Label>
              <Form.Control as="textarea" rows={3} placeholder="Type your message here" required />
            </Form.Group>

            <Button variant="primary" type="submit">
              Send
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
    </div>
  );
};

export default ContactUs;
